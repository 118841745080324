import { FRENCH_LOCALE_ID, GERMAN_LOCALE_ID, JAPANESE_LOCALE_ID, PORTUGEESE_LOCALE_ID, SPANISH_LOCALE_ID } from "/constants/localization";
import { SUPPORTED_LOCALE_IDS } from "constants/localization";
import { CRM_MINI_SITE_LINK, ENTERPRISE_OLD_PAGE_LINK, SOFTWARE_MINI_SITE_LINK, WORK_MANAGEMENT_MINI_SITE_LINK } from "constants/links";
import WM_LOGOS from "constants/work-managment-logos";
const SPANISH_HELP_CENTER_LINK = "//support.monday.com/hc/es";
const FRENCH_HELP_CENTER_LINK = "//support.monday.com/hc/fr";
const PORTUGEESE_HELP_CENTER_LINK = "//support.monday.com/hc/es";
const GERMAN_HELP_CENTER_LINK = "//support.monday.com/hc/de";
const JAPANESE_HELP_CENTER_LINK = "//support.monday.com/hc/ja";
const SPANISH_BLOG_LINK = "//monday.com/blog/es/";
const PORTUGEESE_BLOG_LINK = "//monday.com/blog/pt/";
const FRENCH_BLOG_LINK = "//monday.com/blog/fr/";
const GERMAN_BLOG_LINK = "//monday.com/blog/de/";
const JAPANESE_BLOG_LINK = "//monday.com/blog/ja/";
const SPANISH_WORK_OS_LINK = "//monday.com/blog/es/work-os/";
const PORTUGEESE_WORK_OS_LINK = "//monday.com/blog/pt/work-os-uma-plataforma-para-o-futuro-do-trabalho/";
const FRENCH_WORK_OS_LINK = "//monday.com/blog/fr/le-work-os-lavenir-du-travail/";
export const LocalizedResourcesLinks = {
    [SPANISH_LOCALE_ID]: {
        "header.blog": {
            href: SPANISH_BLOG_LINK
        },
        "header.helpCenter": {
            href: SPANISH_HELP_CENTER_LINK
        },
        "footer.blog": {
            href: SPANISH_BLOG_LINK,
            openInNewTab: true
        },
        "footer.helpCenter": {
            href: SPANISH_HELP_CENTER_LINK,
            openInNewTab: true
        }
    },
    [PORTUGEESE_LOCALE_ID]: {
        "header.blog": {
            href: PORTUGEESE_BLOG_LINK
        },
        "header.helpCenter": {
            href: PORTUGEESE_HELP_CENTER_LINK
        },
        "footer.blog": {
            href: PORTUGEESE_BLOG_LINK,
            openInNewTab: true
        },
        "footer.helpCenter": {
            href: PORTUGEESE_HELP_CENTER_LINK,
            openInNewTab: true
        }
    },
    [FRENCH_LOCALE_ID]: {
        "header.blog": {
            href: FRENCH_BLOG_LINK
        },
        "header.helpCenter": {
            href: FRENCH_HELP_CENTER_LINK
        },
        "footer.blog": {
            href: FRENCH_BLOG_LINK,
            openInNewTab: true
        },
        "footer.helpCenter": {
            href: FRENCH_HELP_CENTER_LINK,
            openInNewTab: true
        }
    },
    [GERMAN_LOCALE_ID]: {
        "header.blog": {
            href: GERMAN_BLOG_LINK
        },
        "header.helpCenter": {
            href: GERMAN_HELP_CENTER_LINK
        },
        "footer.blog": {
            href: GERMAN_BLOG_LINK,
            openInNewTab: true
        }
    },
    [JAPANESE_LOCALE_ID]: {
        "header.blog": {
            href: JAPANESE_BLOG_LINK
        },
        "header.helpCenter": {
            href: JAPANESE_HELP_CENTER_LINK
        },
        "footer.blog": {
            href: JAPANESE_BLOG_LINK,
            openInNewTab: true
        }
    }
};
const DEFAULT_LINKS = {
    "header.blog": {
        href: "//monday.com/blog"
    }
};
export const LocalizedOurProductFooterLinksV2 = {
    [SPANISH_LOCALE_ID]: {
        "footer.workOS": SPANISH_WORK_OS_LINK
    },
    [PORTUGEESE_LOCALE_ID]: {
        "footer.workOS": PORTUGEESE_WORK_OS_LINK
    },
    [FRENCH_LOCALE_ID]: {
        "footer.workOS": FRENCH_WORK_OS_LINK
    }
};
// TODO: Temp solution until we refactor the whole footer and header localization mechanism
// for WM Generator vs. Webflow mini site test
export const LocalizedProductsHeaderLinks = SUPPORTED_LOCALE_IDS.reduce((acc, localeId)=>{
    acc[localeId] = {
        // Products
        "header.tabs.products.mondayWorkManagement.title": `/lang/${localeId}${WORK_MANAGEMENT_MINI_SITE_LINK}`,
        "header.tabs.products.mondaySalesCRM.title": `/lang/${localeId}${CRM_MINI_SITE_LINK}`,
        "header.tabs.products.mondayDev.title": `/lang/${localeId}${SOFTWARE_MINI_SITE_LINK}`,
        // Switcher
        "header.tabs.solutions.byProduct.workManagement": `/lang/${localeId}${WORK_MANAGEMENT_MINI_SITE_LINK}`,
        "header.tabs.solutions.byProduct.crm": `/lang/${localeId}${CRM_MINI_SITE_LINK}`,
        "header.tabs.solutions.byProduct.dev": `/lang/${localeId}${SOFTWARE_MINI_SITE_LINK}`,
        // Solutions by team
        "header.tabs.solution.sales": `/lang/${localeId}${CRM_MINI_SITE_LINK}`,
        "header.tabs.solution.product": `/lang/${localeId}${SOFTWARE_MINI_SITE_LINK}`,
        "header.tabs.solution.rnd": `/lang/${localeId}${SOFTWARE_MINI_SITE_LINK}`,
        // Solutions by Company Size
        "header.tabs.solution.enterprise": `/lang/${localeId}${ENTERPRISE_OLD_PAGE_LINK}`
    };
    return acc;
}, {});
export const LocalizedProductsFooterLinks = SUPPORTED_LOCALE_IDS.reduce((acc, localeId)=>{
    acc[localeId] = {
        "footer.mondayWorkManagement": {
            href: `/lang/${localeId}${WORK_MANAGEMENT_MINI_SITE_LINK}`,
            icon: WM_LOGOS.WM_FOOTER_LOGO
        },
        "footer.mondaySalesCRM": {
            href: `/lang/${localeId}${CRM_MINI_SITE_LINK}`,
            icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/crm_icon_footer.png"
        },
        "footer.mondayDev": {
            href: `/lang/${localeId}${SOFTWARE_MINI_SITE_LINK}`,
            icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/dev_new_mobile_footer_logo.png"
        }
    };
    return acc;
}, {});
export const LocalizedUseCasesFooterLinks = SUPPORTED_LOCALE_IDS.reduce((acc, localeId)=>{
    acc[localeId] = {
        "footer.salesUseCase": `/lang/${localeId}${CRM_MINI_SITE_LINK}`,
        "footer.developers": `/lang/${localeId}${SOFTWARE_MINI_SITE_LINK}`
    };
    return acc;
}, {});
export const LocalizedSolutionsFooterLinks = SUPPORTED_LOCALE_IDS.reduce((acc, localeId)=>{
    acc[localeId] = {
        "footer.enterprise": `/lang/${localeId}${ENTERPRISE_OLD_PAGE_LINK}`
    };
    return acc;
}, {});
// SUPPORTED_LOCALE_IDS
export const getLocalizedLink = (link, localeId)=>{
    var _DEFAULT_LINKS_link;
    const localizedLinks = LocalizedResourcesLinks[localeId];
    return localizedLinks && localizedLinks[link] ? localizedLinks[link].href : ((_DEFAULT_LINKS_link = DEFAULT_LINKS[link]) === null || _DEFAULT_LINKS_link === void 0 ? void 0 : _DEFAULT_LINKS_link.href) || link;
};
